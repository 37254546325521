var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_vm._m(0),_c('alert',{attrs:{"value":_vm.errorMessages.length > 0,"type":"error","canClose":true},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),_c('v-row',{staticClass:"table-simple-filter"},[_c('c-col',[_c('div',{staticClass:"timeline-filters mt-1"},[_c('select-input',{attrs:{"items":_vm.soinsTypes,"label":"Filtrer par type de consultation","name":"filter","clearable":""},on:{"change":function($event){return _vm.filterByCategory($event)}}})],1)])],1),_c('c-data-table',{attrs:{"headers":_vm.headersDocument,"items":_vm.appointments,"sort-by":['start'],"sort-desc":[true],"show-expand":"","loading":!_vm.ready,"item-class":"rowClass","items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: !_vm.ready }},scopedSlots:_vm._u([{key:"item.consultationTypeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeDeConsultation(item.consultationTypeId))+" ")]}},{key:"item.roomId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSiteNameByRoomId(item.roomId))+" ")]}},{key:"item.nurseId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInfirmiereNameById(item.nurseId))+" ")]}},{key:"item.statusId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusName(item.statusId))+" ")]}},{key:"item.importantInfo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[(item.importantInfo)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"no-link-tooltip",attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.importantInfo))])]):_vm._e()],1)]}},{key:"item.cancelReason",fn:function(ref){
var item = ref.item;
return [_c('limited-char',{attrs:{"text":item.cancelReason}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[(item.statusId == 1)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-time",on:{"click":function($event){return _vm.openRDVAgenda(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar-search ")])]}}],null,true)},[_c('span',[_vm._v("Visualiser dans l'agenda")])]):_vm._e()],1)]}},_vm._l((_vm.headersDocument.filter(function (header) { return header.hasOwnProperty('formatter'); })),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('appointment-history',{attrs:{"appointmentId":item.id,"allInfirmieres":_vm.allInfirmieres}})],1)]}}],null,true)})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title"},[_c('h2',[_vm._v("Rendez-vous")])])}]

export { render, staticRenderFns }