import { Component, Vue, Watch } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Commons from '@/components/shared/Helpers/commons'
import { ErrorService } from '@/services/error.service'
import { AgendaService } from '@/services/agenda-service'
import { AppointmentResponseModel } from '@/models/agenda-model'
import { soinsTypes, getSoinName } from '@/views/Administration/Constants'
import { allAgendaStatus } from '@/views/Agenda/constants'
import { RoomService } from '@/services/room-service'
import { RoomModel } from '@/models/rooms-model'
import { AppUser } from '@/models/app-user-dto'
import { AuthService } from '@/services/auth-service'
import AppointmentHistory from './AppointmentHistory/AppointmentHistory.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    AppointmentHistory
  }
})
export default class RDV extends Vue {
  private agendaService = AgendaService.getInstance()
  private roomService = RoomService.getInstance()
  private userService = AuthService.getInstance()

  private selectedDossier!: dossierPatientModel
  public appointments: AppointmentResponseModel[] = []
  public appointmentsClone: AppointmentResponseModel[] = []
  public soinsTypes = soinsTypes
  public rooms: RoomModel[] = []
  public allInfirmieres: AppUser[] = []

  public rdvStatusTypes = allAgendaStatus
  public headersDocument: any[] = []
  public errorMessages: string[] = []
  private ready = false
  private readyRooms = false
  private readyInfirmieres = false
  private readyAppointments = false
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public async mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const that = this
      this.headersDocument = [
        {
          text: '',
          value: 'importantInfo',
          width: '5%'
        },
        {
          text: 'Date',
          value: 'start',
          width: '15%',
          formatter: Commons.TransformDateTimeFormat
        },
        {
          text: 'Type de consultation',
          value: 'consultationTypeId',
          width: '15%',
          sort: function (a, b) {
            return that.getTypeDeConsultation(a)!.localeCompare(that.getTypeDeConsultation(b)!)
          }
        },
        {
          text: 'Site',
          value: 'roomId',
          width: '15%',
          sort: function (a, b) {
            return that.getSiteNameByRoomId(a)!.localeCompare(that.getSiteNameByRoomId(b)!)
          }
        },
        {
          text: 'Intervenant',
          value: 'nurseId',
          width: '15%',
          sort: function (a, b) {
            return that.getInfirmiereNameById(a)!.localeCompare(that.getInfirmiereNameById(b)!)
          }
        },
        {
          text: 'Statut ',
          value: 'statusId',
          width: '15%',
          sort: function (a, b) {
            return that.getStatusName(a)!.localeCompare(that.getStatusName(b)!)
          }
        },
        {
          text: 'Raison',
          value: 'cancelReason',
          width: '15%'
        },
        {
          text: '',
          value: 'actions',
          width: '5%',
          sortable: false,
          align: 'right'
        }
      ]
      this.ready = false
      this.selectedDossier = JSON.parse(cachedDossier) as dossierPatientModel
      Commons.updateDossierName()
      this.getAllRooms()
      this.getAllInfirmiereUsers()
      this.getAppointments()
    } else {
      this.$router.push({ name: 'patient' })
    }
  }

  private updateReadyState () {
    this.ready = this.readyRooms && this.readyInfirmieres && this.readyAppointments
  }

  @Watch('readyRooms')
  public readyRoomsChanged () {
    this.updateReadyState()
  }

  @Watch('readyInfirmieres')
  public readyInfirmieresChanged () {
    this.updateReadyState()
  }

  @Watch('readyAppointments')
  public readyAppointmentsChanged () {
    this.updateReadyState()
  }

  private getAppointments () {
    this.readyAppointments = false
    this.agendaService.getPatientAppointments(this.selectedDossier?.guid).then((appointments) => {
      this.appointments = appointments
      this.setRowsClass()
      this.appointmentsClone = this.appointments
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyAppointments = true
    })
  }

  public getAllRooms () {
    this.readyRooms = false
    this.roomService.getAllRooms().then((rooms) => {
      this.rooms = rooms
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyRooms = true
    })
  }

  public getAllInfirmiereUsers () {
    this.readyInfirmieres = false
    this.userService.getAllInfirmiereGroupUsers().then((infirmieres) => {
      this.allInfirmieres = infirmieres
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyInfirmieres = true
    })
  }

  public getTypeDeConsultation (consultationTypeId: number) {
    return getSoinName(consultationTypeId)
  }

  public getSiteNameByRoomId (roomId: number) {
    return this.rooms.find(x => x.id === roomId)?.site
  }

  public getInfirmiereNameById (nurseId: string) {
    return Commons.FindNurse(this.allInfirmieres, nurseId)?.fullName
  }

  public getStatusName (status: number) {
    return this.rdvStatusTypes.find(x => x.value === status)?.text
  }

  public setRowsClass () {
    Commons.setRowClasses(this.appointments)
  }

  public filterByCategory (event?: number) {
    if (!event) {
      this.appointments = [...this.appointmentsClone]
    } else {
      const filterresults = this.appointmentsClone.filter(x => x.consultationTypeId === event)
      this.appointments = filterresults
    }
    this.setRowsClass()
  }

  public openRDVAgenda (appointment: AppointmentResponseModel) {
    appointment.siteId = this.rooms.find(x => x.id === appointment.roomId)?.siteId
    this.agendaService.updateAppointmentSelected(appointment)
    this.$router.push({ name: 'Agenda' })
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
