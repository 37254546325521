import { AppointmentHistoryResponseModel } from '@/models/agenda-model'
import { AgendaService } from '@/services/agenda-service'
import { ErrorService } from '@/services/error.service'
import { Component, Vue, Prop } from 'vue-property-decorator'
import EmptyTable from '@/components/shared/EmptyTable.vue'
import Alert from '@/components/shared/Alert.vue'
import { allAgendaStatus } from '@/views/Agenda/constants'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    EmptyTable,
    Alert
  }
})
export default class AppointmentHistory extends Vue {
  @Prop()
  public appointmentId!: number

  @Prop({ default: [] })
  public allInfirmieres!: AppUser[]

  private agendaService = AgendaService.getInstance()

  public isHistoryLoading = false
  public history: AppointmentHistoryResponseModel[] = []
  public errorMessages: string[] = []
  public headers: any[] = []
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.headers = [
      {
        text: 'Action',
        value: 'actionType',
        width: '10%'
      },
      {
        text: 'Date',
        value: 'date',
        width: '15%'
      },
      {
        text: 'Patient',
        value: 'patientName',
        width: '15%',
        sort: function (a, b) {
          return a.localeCompare(b!)
        }
      },
      {
        text: 'Intervenant',
        value: 'nurseId',
        width: '15%',
        sort: function (a, b) {
          return that.getNurseName(a)!.localeCompare(that.getNurseName(b)!)
        }
      },
      {
        text: 'Utilisateur',
        value: 'userEmail',
        width: '15%'
      },
      {
        text: 'Status',
        value: 'appointmentStatusId',
        width: '15%',
        sort: function (a, b) {
          return that.getAgendaStatusName(a)!.localeCompare(that.getAgendaStatusName(b)!)
        }
      },
      {
        text: 'Raison',
        value: 'cancelReason',
        width: '15%'
      }
    ]
    this.getHistory()
  }

  public getHistory () {
    this.isHistoryLoading = true
    this.agendaService.getAppointmentHistory(this.appointmentId)
      .then(response => {
        this.history = response
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isHistoryLoading = false
      })
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public getAgendaStatusName (statusId: number) {
    return allAgendaStatus.find(s => s.value === statusId)?.text
  }

  public getNurseName (nurseId: string) {
    return Commons.FindNurse(this.allInfirmieres, nurseId)?.fullName
  }
}
