var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"embeded-table"},[_c('alert',{attrs:{"type":"error","canClose":true,"value":_vm.errorMessages.length > 0},on:{"close":_vm.hideAlert}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)]),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.history,"hide-default-footer":_vm.history.length <= 10,"sort-by":['date'],"sort-desc":[true],"mobile-breakpoint":600,"loading":_vm.isHistoryLoading,"item-key":"id","items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.isHistoryLoading }},scopedSlots:_vm._u([{key:"item.appointmentStatusId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAgendaStatusName(item.appointmentStatusId))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('em',[_vm._v(_vm._s(_vm._f("date-time")(item.date)))])]}},{key:"item.nurseId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getNurseName(item.nurseId))+" ")]}},{key:"item.cancelReason",fn:function(ref){
var item = ref.item;
return [_c('limited-char',{attrs:{"text":item.cancelReason}})]}},{key:"no-data",fn:function(){return [_c('empty-table',{attrs:{"isLoading":_vm.isHistoryLoading},on:{"refresh-click":function($event){return _vm.getHistory()}}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }